// routes
import {  PATH_PAGE } from '../../../routes/paths';
// config

// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/',
  },
  {
    title: 'FAQs',
    icon: <Iconify icon="ic:round-grain" />,
    path: PATH_PAGE.faqs,
  },
	{
    title: 'Whitepaper',
    icon: <Iconify icon="ic:round-grain" />,
    path: PATH_PAGE.whitepaper,
  },
];

export default navConfig;
