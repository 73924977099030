
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
// @mui

import {  BoxProps, SvgIcon } from '@mui/material'
// import { ReactComponent as LogoImage } from '../logo/logo_single.svg'
import { ReactComponent as LogoImage } from '../../theme/logo/logo_darkbackground.svg'


// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean
}

export default function Logo({ disabledLink = false, sx }: Props) {
	// const theme = useTheme()

	const logo = 	(<SvgIcon component={LogoImage} sx={{ height: 14, width: 'auto', ...sx }} inheritViewBox />)

	if (disabledLink) {
		return <>{logo}</>
	}

	return <RouterLink to="/">{logo}</RouterLink>
}
