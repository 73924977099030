import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
// @mui

import {  BoxProps, SvgIcon } from '@mui/material'
// import { ReactComponent as LogoImage } from '../logo/logo_single.svg'
import { ReactComponent as LogoImage } from '../../theme/logo/icon_darkbackground.svg'

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean
}

export default function Logo({ disabledLink = false, sx }: Props) {
	

	// const PRIMARY_LIGHT = theme.palette.primary.light

	// const PRIMARY_MAIN = theme.palette.primary.main

	// const PRIMARY_DARK = theme.palette.primary.dark

	// OR
	const logo = 	(<SvgIcon component={LogoImage} sx={{ width: 40, height: 40, ...sx }} inheritViewBox />)
	if (disabledLink) {
		return <>{logo}</>
	}

	return <RouterLink to="/">{logo}</RouterLink>
}
