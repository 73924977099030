import { memo } from 'react';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
//
import BackgroundIllustration from './BackgroundIllustration';

// ----------------------------------------------------------------------

function UpgradeBetaSpots({ ...other }: BoxProps) {
  // const theme = useTheme();

 

  return (
    <Box {...other}>
      <svg width="100%" height="100%" viewBox="0 0 480 360" xmlns="http://www.w3.org/2000/svg">
        <BackgroundIllustration />
        <image
          href="/assets/images/dashboard/requestbetaspots.png"
          height="300"
          x="35"
          y="30"
        />
      </svg>
    </Box>
  );
}

export default memo(UpgradeBetaSpots);
