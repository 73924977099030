// import app from 'gatsby-plugin-firebase-v9.0-typescript'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getDatabase, connectDatabaseEmulator } from 'firebase/database'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import { FIREBASE_API, localhostAlias } from '../config-global'

let emulatorEnabled = false

let isFirebaseInitialized = false

export const getFirebase = () => {
	if (!isFirebaseInitialized) {
		initializeApp(FIREBASE_API)
		isFirebaseInitialized = true
	}
}

const activateFirebaseEmulator = (): void => {
	if (process.env.REACT_APP_USE_EMULATOR === 'true') {
		if (emulatorEnabled) return
		emulatorEnabled = true
		console.log(`gonna use emulator for client-side via "${localhostAlias}"`)

		const auth = getAuth()
		connectAuthEmulator(auth, `http://${localhostAlias}:${process.env.REACT_APP_FB_AUTH_PORT}`)

		const functions = getFunctions()
		connectFunctionsEmulator(functions, localhostAlias, parseInt(process.env.REACT_APP_FB_FUNCTIONS_PORT as string, 10))

		const db = getFirestore()
		connectFirestoreEmulator(db, localhostAlias, parseInt(process.env.REACT_APP_FB_FIRESTORE_PORT as string, 10))

		const rt = getDatabase()
		connectDatabaseEmulator(rt, localhostAlias, parseInt(process.env.REACT_APP_FB_DATABASE_PORT as string, 10))
	}
}

export default activateFirebaseEmulator
